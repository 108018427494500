import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import Reducers, { rootSaga } from './Reducers';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(Reducers, applyMiddleware(thunk, sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
