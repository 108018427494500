import { delay, put, takeLatest } from 'redux-saga/effects';

export const LRN_PLUS = 'LRN_PLUS';
export const LRN_MINUS = 'LRN_MINUS';
export const NAL_LOADING = 'NAL_LOADING';
export const NAL_SUCCESS = 'NAL_SUCCESS';
export const loadinga = 'loadinga';
export const increasea = 'increasea';
export const decreasea = 'decreasea';
export const increase_async = 'increase_async';
export const decrease_async = 'decrease_async';

export const loading = () => ({ type: loadinga });
export const increase = () => ({ type: increasea });
export const decrease = () => ({ type: decreasea });

export function learningPlus() {
  return {
    type: LRN_PLUS,
  };
}

export function learningMinus() {
  return {
    type: LRN_MINUS,
  };
}

export function nalssiLoading() {
  return {
    type: NAL_LOADING,
  };
}

export function nalssiSuccess(temperature, name) {
  return {
    type: NAL_SUCCESS,
    temperature,
    name,
  };
}

export const increaseasync = () => ({ type: increase_async });
export const decreaseasync = () => ({ type: decrease_async });

function* increaseSaga() {
  yield put(loading());
  yield delay(4321);
  yield put(increase());
}

function* decreaseSaga() {
  yield delay(1000);
  yield put(decrease());
}

export function* counterSaga() {
  yield takeLatest(increase_async, increaseSaga);
  yield takeLatest(decrease_async, decreaseSaga);
}
