import React from 'react';

import Header from './DomCompo/Header';
import './App.scss';
import Visual from './DomCompo/Visual';
import Footer from './DomCompo/Footer';
import PlayModal from './DomCompo/PlayModal';
import PlayGuide from './DomCompo/PlayGuide';

import DesktopInfo from './DomCompo/DesktopInfo';
import DesktopInfoBackGr from './DomCompo/DesktopInfo_copy';

function App() {
  return (
    <div className="body__container">
      <Header />
      <Visual /> 
      <div className="flex_container">
        <div className="flex_content">
        {/*
          <Profile />
          <Learning />
        */}     
        </div>
        <div className="flex_contents">
          {/* <DesktopInfoBackGr/>   */}
          <DesktopInfo /> 

        </div>
        <div className="playmodalContent">
          <PlayGuide />
          <PlayModal />
        </div>
      </div>
      <br></br>
      <div className="playmodal">
        <PlayGuide />
        <PlayModal />
      </div>
      {/* <Mapgoo /> */}
      <Footer />
    </div>
  );
}

export default App;
