import React, { useState, useEffect, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import Text from './Text';
import './styles.css';

 //useState, useEffect 상태관련
 //Dino, forWeather 참고

export function Jumbo(props) {
  const ref = useRef();
  useFrame(
    ({ clock }) =>
      (ref.current.rotation.x = Math.sin(clock.getElapsedTime()) * 0.5),
  );
  return (
    <group ref={ref}>
      <Text
        position={[0, 0, 0]}
        size={13}
        children={props.children}
      />
    </group>
  );
}
