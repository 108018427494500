import { getWinNumbers } from '../Const_etc/lotto';
import { loadinga, increasea, decreasea } from '../action';

const initialstate = {
  loading: false,
  number: 0,
  lotto7: getWinNumbers(),
};

const counter = (state = initialstate, action) => {
  switch (action.type) {
    case loadinga:
      return {
        ...state,
        loading: true,
      };

    case increasea:
      console.log(action);
      return {
        ...state,
        loading: false,
        number: state.number + 1,
      };
    case decreasea:
      return {
        ...state,
        loading: false,
        number: state.number - 1,
      };
    default:
      return state;
  }
};

export default counter;
