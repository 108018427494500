import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { counterSaga } from '../action';
import { LrnReducer } from './LrnReducer';
import { NalReducer } from './NalReducer';
import counter from './LotReducer';

const Reducers = combineReducers({
  LrnReducer,
  NalReducer,
  counter,
});

export function* rootSaga() {
  yield all([counterSaga()]);
}

export default Reducers;
