import React, { Suspense } from 'react';
import '../DomCompoCss/SectionCommon.scss';
import '../DomCompoCss/Visual.scss';
import { Canvas } from 'react-three-fiber';
import { Jumbo, Dino } from '../Compo_etc/simpleThree';
import Box from '../Compo_etc/box';
import { useSelector } from 'react-redux';
const Visual = () => {
  const forWeather = useSelector((state) => state.NalReducer);

  return (
    
           <div className="inner">
             <div className="summary">
               <h2 className="summary__title"></h2>
             </div>
           </div>
    
       
  );
  //   );
  // };
};

export default Visual;
