import React, { useState, useEffect, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import cloud from '../Const_etc/AnyConv.com__Cloud.glb';
import clear from '../Const_etc/AnyConv.com__sunflower.glb';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Text from './Text';
import './styles.css';

export const Dino = ({ forWeather }) => {
  const [model, setModel] = useState('');
  //const [ondo, setondo] = useState('');

  // console.log(useSelector((state) => state.NalReducer));

  // useEffect(() => {
  //   nalUtils().then((result) => {
  //     console.log(result);
  //     setondo(result);
  //   });
  // }, []);

  //const [temperature, weather] = ondo;

  useEffect(() => {
    const { temperature, name } = forWeather;
    //const { temperatured, name } = useSelector((state) => state.NalReducer);
    console.log(temperature, name);
    if (name === 'Clear') {
      new GLTFLoader().load(clear, setModel);
    } else if (name === '......') {
    } else {
      new GLTFLoader().load(cloud, setModel);
    }
  }, [forWeather]);

  useFrame(() => {
    if (model) {
      model.scene.rotation.y += 0.21;
    }
  });
  return model ? (
    <primitive
      // object={model.scenes[0].children[0]}
      object={model.scene}
      position={[0, -22, 0]}
      // castShadow
      // onClick={() => setActive(!active)}
      scale={[7.5, 7.5, 7.5]}
    />
  ) : null;
};

export function Jumbo() {
  const ref = useRef();
  useFrame(
    ({ clock }) =>
      (ref.current.rotation.x = Math.sin(clock.getElapsedTime()) * 0.5),
  );
  return (
    <group ref={ref}>
      <Text
        position={[0, -22.6, 0]}
        size={0.5}
        children="'맑음'?'해바라기':'구름'"
      />
    </group>
  );
}
