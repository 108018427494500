import React, {Suspense} from 'react';
import { Canvas } from 'react-three-fiber';
import { Jumbo } from '../Compo_etc/simpleThree_copy';
import '../DomCompoCss/DesktopInfo.scss';
//import '../DomCompoCss/SectionCommon.scss';


const DesktopInfo = () => {
  return (
   <>
    {//1.
    /* 반응형 모바일에서 테블릿이상으로 가면 복구안됨
    모바일로 갈시 대표 하나만 보이게 해본다(심플하게)
    모바일일떄는 맨나중에 대충이라도 꾸민다 
     버전 pc 11 테블릿 7 모바일 4
    */}

    {//2는 1이되면생략
    /* 메뉴상단바 텝형식으로 다르게 추가 텝형식 슬라이드 느낌으로
    */}
  
    {/* background용 */}
 
  <section className="section backgrsection--visual" style={{position: 'absolute'}}>
    {/* 테블릿일때 안나오고 전체적으로 꺠짐 */}

    <Canvas camera={{ position: [0, 0, 55] }}>
      <ambientLight intensity={1.5} />
      <spotLight intensity={0.6} position={[10, 10, 10]} />
  
      <Suspense fallback={null}>
          <Jumbo children="JAVA"/>
      </Suspense>      

    </Canvas>
   
  </section> 
 

  <div style={{position : 'absolute', top : '5%', left : '1%'}}>
    <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={12.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="1_ARCITEC" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          {/* <ol>
            
          </ol> */}
          <ol>
            <li>Web.xml             []</li>  
            <li>Egov library        []</li>
            <li>ApplicationContext  []</li>
            <li>HttpSessionListener []</li>
            <li>ServletListener     []</li>
          </ol>
        </div>

        {/* </section>     */}
      
    </section>
  </div>

  {/* 3d linux */}
  <div style={{position : 'absolute', top:'5%', left : '20%'}}>
  <section className="section section--visual">

      <Canvas camera={{ position: [0, 0, 55] }}>
        <ambientLight intensity={1.5} />
        <spotLight intensity={0.6} position={[10, 10, 10]} />

        <Suspense fallback={null}>
            <Jumbo children="2_ARCITEC"/>
        </Suspense>
      
      </Canvas>
      <span class="hvr-icon-down" align="right">
        <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
      </span>

      <div className="hide">
        <ol>
            <li>Annotation        []</li>
            <li>Detail Factory    []</li>
            <li>Interface Impl Dao[]</li>
            <li>Vo Lombok         []</li>
            <li>Io Nio            []</li>
            <li>~Template.java    []</li>
        </ol>
        <ul>
        </ul>
      </div>

      </section>    
    
  </div>

  {/* 3d kubernatesㅇ */}
  <div style={{position : 'absolute', top: '5%', left: '55%'}}>
    <section className="section section--visual">

          <Canvas camera={{ position: [0, 0, 55] }}>
            <ambientLight intensity={1.5} />
            <spotLight intensity={0.6} position={[10, 10, 10]} />

            <Suspense fallback={null}>
                <Jumbo children="1_SERVER" />
            </Suspense>
          
          </Canvas>
          <span class="hvr-icon-down" align="right">
            <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
          </span>

          <div className="hide">
            <ol>
                <li>Based Orderment 10[]</li>
                <li>Vi Editor Options []</li>
                <li>OnPromise         []</li>
                <li>Server USE        []</li>
                <li>Security TOOL     []</li>
            </ol>
            <ul>
            </ul>
          </div>

          </section>    
        
    </div>

    {/* 3D  */}
    <div style={{position : 'absolute', top: '5%', left: '77%'}}>
      <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={1.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="2_SERVER" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          <ol>
              <li>Useful Orderment 10[]</li>
              <li>System Orderment 10[]</li>
              <li>Basic Configuration[]</li>
              <li>+Basic Engineering []</li>
              <li>Run DB(Config) Run SQL []</li>
          </ol>
          <ul>
          </ul>
        </div>

        </section>    
      
    </div>

    {/* 3D asd*/}
    <div style={{position : 'absolute', top: '5%', left: '99%'}}>
      <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={1.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="MSA?" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          <ol>
              <li>Priority    []</li>
              <li>Obsessment  []</li>
          </ol>
          <ul>
          </ul>
        </div>

        </section>    
      
    </div>

    {/* 3D file(백기선)*/}
    <div style={{position : 'absolute', top: '33%', left: '1%'}}>
      <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={1.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="DESIGN" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          <ol>
          </ol>
          <ul>
          </ul>
        </div>

        </section>    
      
    </div>

    

    {/* 3D jpa2*/}
    <div style={{position : 'absolute', top: '33%', left: '31%'}}>
      <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={1.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="JPA" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          <ol>
          </ol>
          <ul>
          </ul>
        </div>

        </section>    
      
    </div>



    {/* 3D file(디자인패턴)*/}
    <div style={{position : 'absolute', top: '33%', left: '58%'}}>
      <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={1.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="IDE[전정]" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          <ol>
            <li>HOW TO USE</li>
          </ol>
          <ul>
          </ul>
        </div>

      </section>    
      
    </div>




    {/* 3D file(디자인패턴)*/}
    <div style={{position : 'absolute', top: '33%', left: '82%'}}>
      <section className="section section--visual">

        <Canvas camera={{ position: [0, 0, 55] }}>
          <ambientLight intensity={1.5} />
          <spotLight intensity={0.6} position={[10, 10, 10]} />

          <Suspense fallback={null}>
              <Jumbo children="IDE[인텔]" />
          </Suspense>
        
        </Canvas>
        <span class="hvr-icon-down" align="right">
          <i class="fa fa-arrow-circle-o-down hvr-icon"></i>
        </span>

        <div className="hide">
          <ol>
            <li>HOW TO USE</li>
          </ol>
          <ul>
          </ul>
        </div>

      </section>    
      
    </div>
 
    </>
  );
};

export default DesktopInfo;
