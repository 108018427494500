import Img_mark1 from '../pub1/react-redux.png';
import Img_mark2 from '../pub1/spring.png';
import Img_mark3 from '../pub1/.net.png';
import Img_mark4 from '../pub1/sass-down.png';

const learns = [
  {
    title: 'skils: react',
    isAbout: 'most interested library',
    display: 'none',
    Img_mark: Img_mark1,
  },
  {
    title: 'skils: spring framework',
    isAbout: 'used in OSSE corp',
    display: 'none',
    Img_mark: Img_mark2,
  },
  {
    title: 'skils: .net framework',
    isAbout: 'used in OSSE corp',
    display: 'none',
    Img_mark: Img_mark3,
  },
  {
    title: 'this stie used',
    isAbout: ' react, react-redux(thunk), sass, api',
    display: 'none',
    Img_mark: Img_mark4,
  },
];

export default learns;
