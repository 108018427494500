import React, { memo, useEffect, Suspense } from 'react';
import '../DomCompoCss/Header.scss';
import '../DomCompoCss/SectionCommon.scss';
import { nalUtilsThunk } from '../Const_etc/nalUtils';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Canvas } from 'react-three-fiber';
import { Jumbo, Dino } from '../Compo_etc/simpleThree';



const Header = memo(() => {
  const forWeather = useSelector((state) => state.NalReducer);
  const { temperature, name } = useSelector((state) => state.NalReducer);

  console.log(useSelector((state) => state.NalReducer));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(nalUtilsThunk());
  }, [temperature]);

  return (
    <header>
      <div className="inner clearfix">
        <div className="menu-group float--left">
        </div>

        <div className="float--right">
       
          <ul className="weathering">
            <li
              onClick={() => {
                dispatch(nalUtilsThunk());
              }}
            ></li>
            <li style={{
                      top: '-22px',
                      left : '-129px',
                      position : 'absolute'
                    }}>
             {/* 3d  */}
             <section className="section section--visual">

                <Canvas camera={{ position: [0, 0, 55] }}>
                  <ambientLight intensity={1.5} />
                  <spotLight intensity={0.6} position={[10, 10, 10]} />

                  <Suspense fallback={null}>
                    {/* <Jumbo /> */}
                  </Suspense>
                  <Dino forWeather={forWeather} />
                </Canvas>
                    
            </section>
            {/* 3d  */}
            </li>
            <li>{`${temperature}'C`}</li>
            <li>{name}</li>
          </ul>
        </div>
        <div id="toggle-btn">Header Menu Toggle Button</div>
      </div>
    </header>
  );
});

export default memo(Header);
